import {
  Container,
  Group,
  Stack,
  Text,
  ThemeIcon,
  useMantineTheme,
} from "@mantine/core";
import {
  IconStar,
  IconStarFilled,
  IconStarHalfFilled,
} from "@tabler/icons-react";
import React from "react";
import { HugeTitle } from "./HugeTitle";

interface RatingProps {
  rating: number; // Rating number (e.g., 4.5)
  totalStars?: number; // Total stars to display (default: 5)
  message?: string;
  titleStyles?: any;
}

const Ratings = ({
  rating,
  totalStars = 5,
  message,
  titleStyles,
}: RatingProps) => {
  const theme = useMantineTheme();

  function getStars() {
    const stars = [];

    for (let i = 0; i < totalStars; i++) {
      if (i < Math.floor(rating)) {
        // Full star
        stars.push(
          <ThemeIcon variant="white" size={"sm"} hiddenFrom="md">
            <IconStarFilled
              key={`star-${i}`}
              style={{
                color: theme.colors.yellow[6],
              }}
            />
          </ThemeIcon>
        );
        stars.push(
          <ThemeIcon variant="white" size={"md"} visibleFrom="md">
            <IconStarFilled
              key={`star-${i}`}
              style={{
                color: theme.colors.yellow[6],
              }}
            />
          </ThemeIcon>
        );
      } else if (i < rating) {
        // Half star
        stars.push(
          <ThemeIcon variant="white" size={"sm"} hiddenFrom="md">
            <IconStarHalfFilled
              key={`star-${i}`}
              style={{
                color: theme.colors.yellow[6],
              }}
            />
          </ThemeIcon>
        );
        stars.push(
          <ThemeIcon variant="white" size={"md"} visibleFrom="md">
            <IconStarHalfFilled
              key={`star-${i}`}
              style={{
                color: theme.colors.yellow[6],
              }}
            />
          </ThemeIcon>
        );
      } else {
        // Empty star
        stars.push(
          <ThemeIcon variant="white" size={"sm"} hiddenFrom="md">
            <IconStar
              key={`star-${i}`}
              style={{
                color: theme.colors.gray[6],
              }}
            />
          </ThemeIcon>
        );
        stars.push(
          <ThemeIcon variant="white" size={"md"} visibleFrom="md">
            <IconStar
              key={`star-${i}`}
              style={{
                color: theme.colors.gray[6],
              }}
            />
          </ThemeIcon>
        );
      }
    }

    return stars;
  }

  return (
    <Stack align="center" gap={10}>
      <HugeTitle
        style={
          titleStyles ?? {
            fontSize: "2rem",
            fontStyle: "italic",
            fontWeight: 400,
          }
        }
      >
        Rated
      </HugeTitle>
      <Group align="flex-end" gap={3}>
        <Text fw={500} style={{ fontSize: "2rem" }} lh={1} c="dark">
          {rating.toFixed(1)}
        </Text>
        <Text style={{ fontSize: "1.2rem" }} lh={1.4} c="dimmed">
          /
        </Text>
        <Text style={{ fontSize: "1.2rem" }} lh={1.25} c="dimmed">
          {totalStars}
        </Text>
      </Group>
      <Group gap={3} align="flex-end">
        {getStars()}
      </Group>
      {message && (
        <HugeTitle
          style={{ fontSize: "1rem", fontStyle: "italic" }}
          fw={400}
          c="dimmed"
        >
          {message}
        </HugeTitle>
      )}
    </Stack>
  );
};

export default Ratings;
