import {
  BackgroundImage,
  Box,
  Center,
  Container,
  Group,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";
import footerBg from "../assets/images/wave.svg";
import { HugeTitle } from "./Shared/HugeTitle";

function Footer() {
  return (
    <Container
      fluid
      p={0}
      m={0}
      style={{ backgroundImage: 'url("assets/images/waves.svg")' }}
    >
      <Box mx="auto" style={{ borderRadius: 0 }}>
        <BackgroundImage src={footerBg} h={350}>
          <Group
            style={{ height: "inherit", width: "100%" }}
            align="flex-end"
            justify="center"
            pb={15}
            visibleFrom="md"
          >
            <Stack align="center" gap={50}>
              <HugeTitle c="white">{process.env.REACT_APP_NAME}</HugeTitle>
              <Text c={"white"} size="xs">
                Copyright Info: © 2024 {process.env.REACT_APP_NAME}. All rights
                reserved.
              </Text>
            </Stack>
          </Group>

          <Group
            style={{ height: "inherit", width: "100%" }}
            align="flex-end"
            justify="center"
            pb={15}
            hiddenFrom="md"
          >
            <Stack align="center" gap={50}>
              <HugeTitle c="white" style={{ fontSize: "2.3rem" }}>
                {process.env.REACT_APP_NAME}
              </HugeTitle>
              <Text c={"white"} size="xs">
                Copyright Info: © 2024 {process.env.REACT_APP_NAME}. All rights
                reserved.
              </Text>
            </Stack>
          </Group>
        </BackgroundImage>
      </Box>
    </Container>
  );
}

export default Footer;
