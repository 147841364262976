const gtag = (
  eventType: string,
  eventName: string,
  eventData: Record<string, any> = {}
) => {
  (window as unknown as any).gtag(eventType, eventName, eventData);
};

export function logEvent(
  eventName: string,
  eventData: Record<string, any> = {}
) {
  const eventDataConfig = {
    send_to: process.env.REACT_APP_ADS_EVENT_SENT_TO_ID,
    value: 1.0,
  };
  gtag("event", eventName, eventDataConfig);
}
