import emailjs from "@emailjs/browser";
import { createTheme, DEFAULT_THEME, MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import "@mantine/carousel/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

emailjs.init({
  publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
});


// Define a theme with updated modern fonts and rounded components
const theme = createTheme({
  ...DEFAULT_THEME, // Ensure all default colors and scales are included

  fontFamily: "Inter, sans-serif", // Modern, simple body font
  fontFamilyMonospace: "Fira Code, monospace", // Clean monospace for code-like text
  headings: { fontFamily: "Poppins, sans-serif" }, // Stylish yet minimal headings
  fontSizes: {
    xs: "12px",
    sm: "14px",
    md: "16px",
    lg: "18px",
    xl: "20px",
    "2md": "24px",
    "3md": "30px",
    "4md": "36px",
    "5md": "48px",
    "6md": "60px",
  },

  // Component customizations
  components: {
    Button: {
      defaultProps: {
        radius: "md", // Rounded buttons by default
        color: DEFAULT_THEME.colors.green[8],
      },
      styles: (theme: any) => ({
        root: {
          fontFamily: theme.fontFamily,
          padding: `0 ${theme.spacing.md}`, // Adjust padding for rounded buttons
        },
      }),
    },
    NativeSelect: {
      defaultProps: {
        radius: "md", // Rounded inputs by default
      },
      styles: (theme: any) => ({
        input: {
          fontFamily: theme.fontFamily,
          padding: `auto`,
        },
      }),
    },
    Input: {
      defaultProps: {
        radius: "md", // Rounded inputs by default
      },
      styles: (theme: any) => ({
        input: {
          fontFamily: theme.fontFamily,
          padding: `${theme.spacing.sm} ${theme.spacing.md}`, // Adjust padding for a balanced design
        },
      }),
    },
    TextInput: {
      defaultProps: {
        radius: "md", // Rounded text inputs by default
      },
    },
    Textarea: {
      defaultProps: {
        radius: "md", // Rounded textareas by default
      },
    },
    Select: {
      defaultProps: {
        radius: "md", // Rounded selects by default
      },
    },
    DatePickerInput: {
      defaultProps: {
        radius: "md", // Rounded selects by default
      },
    },
    Card: {
      defaultProps: {
        radius: "md", // Rounded cards by default
      },
      styles: (theme: any) => ({
        root: {
          fontFamily: theme.fontFamily,
          padding: theme.spacing.md, // Comfortable padding
          boxShadow: theme.shadows.sm, // Default shadow for cards
        },
      }),
    },
    Modal: {
      defaultProps: {
        radius: "md", // Rounded modals by default
      },
      styles: (theme: any) => ({
        root: {
          borderRadius: theme.radius.md, // Ensure rounding is applied
        },
      }),
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <MantineProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </MantineProvider>
  </React.StrictMode>
);

reportWebVitals();
