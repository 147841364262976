import axios from "axios";

export async function createEnquiry(
  service: string,
  projectTitle: string,
  stdCode: string,
  contactNumber: string,
  email: string,
  allowMarketingNotifications: boolean,
  deadline?: Date | string
): Promise<any> {
  return await axios.post(`${process.env.REACT_APP_API_URL}/client/enquiry`, {
    service,
    projectTitle,
    stdCode,
    contact: contactNumber,
    contactNumber,
    email,
    allowMarketingNotifications,
    deadline,
  });
}
