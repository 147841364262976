import { Box, Container, Group, Stack, useMantineTheme } from "@mantine/core";
import { useEffect } from "react";

function SectionHeader({ children }: any) {
  const theme = useMantineTheme();
  return (
    <Container
      style={{
        background: "white",
        borderRadius: theme.radius.xs,
      }}
      py={"1rem"}
    >
      <Group align="flex-start" justify="flex-start" grow>
        <Stack gap={5}>
          {children}
          <Box
            style={{
              width: "100%",
              background: theme.colors.green[9],
              fontSize: "0.1rem",
              color: "transparent",
              borderRadius: theme.radius.xl,
            }}
            py={1}
          >
            .
          </Box>
        </Stack>
      </Group>
    </Container>
  );
}

export default SectionHeader;
