import {
  Avatar,
  Card,
  Container,
  Grid,
  Group,
  rem,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { Carousel } from "@mantine/carousel";
import {
  IconRosetteDiscountCheckFilled,
  IconStarFilled,
} from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import SectionHeader from "./Shared/SectionHeader";
import { HugeTitle } from "./Shared/HugeTitle";
import { Testimonial } from "../Model/Testimonial.model";
import { getAllTestimonials } from "../Services/content.service";
import { format } from "date-fns";
import { useMediaQuery } from "@mantine/hooks";

function Testimonials() {
  const [testimonials, setTestimonials] = useState<Testimonial[]>([]);
  const theme = useMantineTheme();
  const [activeIndex, setActiveIndex] = useState(0);
  async function loadAllTestimonials() {
    try {
      const res = await getAllTestimonials();
      if (res?.data) {
        console.log(res?.data);
        setTestimonials(res?.data);
      } else {
        console.log("No testimonials");
      }
    } catch (e) {
      console.log("Unable to load testimonials");
    }
  }

  useEffect(() => {
    loadAllTestimonials();
  }, []);

  return (
    <Stack gap={0}>
      <Stack gap={0}>
        <SectionHeader>
          <HugeTitle style={{ fontSize: "2.5rem" }}>Success Stories</HugeTitle>
        </SectionHeader>
        <div
          id="reviews"
          style={{
            opacity: 0,
            width: "100%",
            height: 0,
            display: "block",
            background: "transparent",
          }}
        ></div>
      </Stack>
      <Container mt={0} fluid>
        <Carousel
          slideSize={{
            base: "100%",
            sm: "50%",
            md: "30%",
          }}
          height={490}
          slideGap="xl"
          withControls={false}
          loop
          draggable={true}
          align="center"
          onSlideChange={(index: number) => setActiveIndex(index)}
          withIndicators
          styles={{
            indicators: {
              marginTop: "100rem",
            },
            indicator: {
              background: theme.colors.green[8],
              width: "0.65rem",
              height: "0.65rem",
            },
          }}
        >
          {testimonials?.map((testimonial: Testimonial, index: number) => {
            const isCenter = index === activeIndex % testimonials.length;

            return (
              <Carousel.Slide key={"testimonial-" + index}>
                <Group style={{ height: "100%" }}>
                  <TestimonialsCard
                    testimonial={testimonial}
                    isCenter={isCenter}
                  />
                </Group>
              </Carousel.Slide>
            );
          })}
        </Carousel>
      </Container>
    </Stack>
  );
}

interface TestimonialsCardProps {
  testimonial: Testimonial;
  isCenter: boolean;
}

function TestimonialsCard({ testimonial, isCenter }: TestimonialsCardProps) {
  const theme = useMantineTheme();

  function convertNameToTag(name: string) {
    return `@${name.replaceAll(" ", "").toLowerCase()}`;
  }

  function createRatings(userRating: number) {
    const acc = [];
    const rating = Math.ceil(userRating);
    for (let i = 0; i < rating; i++) {
      acc.push(
        <IconStarFilled
          size={"1rem"}
          color={isCenter ? theme.colors.yellow[5] : theme.colors.yellow[7]}
        />
      );
    }
    return <Group gap={3}>{acc}</Group>;
  }

  return (
    <Card
      shadow={"none"}
      radius={"lg"}
      style={{
        width: "auto",
        boxShadow: "none",
        height: "22rem",
        background: isCenter ? theme.colors.dark[7] : "#F5FFFA",
      }}
    >
      <Stack>
        <Stack gap={15}>
          <Group>
            <Avatar
              color={theme.colors.blue[5]}
              radius="xl"
              name={testimonial.name}
              size={"lg"}
              variant="filled"
            />

            <Stack gap={1}>
              <Group gap={10}>
                <Text size="md" fw={550} c={isCenter ? "white" : "dark"}>
                  {testimonial.name}
                </Text>
                <IconRosetteDiscountCheckFilled
                  size={"1.2rem"}
                  color={theme.colors.blue[5]}
                  stroke={rem(0.5)}
                />
              </Group>

              <Text size="sm" c={isCenter ? "white" : "dimmed"} fw={500}>
                {convertNameToTag(testimonial.name)}
              </Text>
            </Stack>
          </Group>

          <Text
            style={{ fontSize: "0.95rem", lineHeight: "1.7rem" }}
            c={isCenter ? "white" : "dark"}
          >
            <ScrollArea h={"13rem"}>
              <div
                dangerouslySetInnerHTML={{ __html: testimonial.comment }}
                style={{ textAlign: "center" }}
              ></div>
            </ScrollArea>
          </Text>
        </Stack>

        <Group justify="space-between" align="center">
          {createRatings(testimonial.rating)}
          <Text size="sm" c="dimmed" fw={512}>
            {format(testimonial.datedOn, "MMMM, yyyy")}
          </Text>
        </Group>
      </Stack>
    </Card>
  );
}

export default Testimonials;
