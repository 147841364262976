import {
  Box,
  Container,
  Group,
  SemiCircleProgress,
  Stack,
  useMantineTheme,
  useMatches,
} from "@mantine/core";
import { IconTruckDelivery } from "@tabler/icons-react";
import DescriptionText from "./Shared/DescriptionText";
import { HugeTitle } from "./Shared/HugeTitle";
import Ratings from "./Shared/Ratings";

function Metrics() {
  const theme = useMantineTheme();
  const titleStyles = useMatches({
    base: {
      fontSize: "1.5rem",
      fontStyle: "italic",
      fontWeight: 500,
    },
    md: {
      fontSize: "3rem",
      fontStyle: "italic",
      fontWeight: 300,
    },
  });

  const accuracyPercentageStyles = useMatches({
    base: {
      fontSize: "1.5rem",
      fontWeight: 600,
    },
    md: {
      fontSize: "2.5rem",
      fontWeight: 400,
    },
  });

  return (
    <Container w={"100%"} p={0} m={0} fluid>
      <Group justify="space-evenly" gap={35}>
        <Ratings
          rating={4.5}
          message="-by Our Clients"
          titleStyles={titleStyles}
        />

        <Stack gap={10} align="center">
          <HugeTitle style={titleStyles}>Accuracy</HugeTitle>
          <Box hiddenFrom="md">
            <SemiCircleProgress
              fillDirection="left-to-right"
              orientation="up"
              size={130}
              filledSegmentColor="green"
              thickness={8}
              value={95}
              label={
                <Stack gap={1}>
                  <HugeTitle style={accuracyPercentageStyles} c="dark">
                    95%
                  </HugeTitle>
                </Stack>
              }
            />
          </Box>
          <Box visibleFrom="md">
            <SemiCircleProgress
              fillDirection="left-to-right"
              orientation="up"
              size={210}
              filledSegmentColor="green"
              thickness={12}
              value={95}
              label={
                <Stack gap={1}>
                  <HugeTitle fw={500} style={{ fontSize: "2.5rem" }} c="dark">
                    95%
                  </HugeTitle>
                </Stack>
              }
            />
          </Box>
          <HugeTitle
            style={{ fontSize: "1rem", fontStyle: "italic" }}
            fw={400}
            c="dimmed"
          >
            Of the Assignments
          </HugeTitle>
        </Stack>

        <Stack gap={7} align="center">
          <HugeTitle style={titleStyles}>Speed</HugeTitle>
          <Box hiddenFrom="md" p={0} m={0}>
            <IconTruckDelivery
              size={80}
              color={theme.colors.blue[7]}
              stroke={1.5}
            />
          </Box>
          <Box visibleFrom="md">
            <IconTruckDelivery
              size={100}
              color={theme.colors.blue[7]}
              stroke={1.5}
            />
          </Box>
          <DescriptionText
            style={{ fontStyle: "italic", fontSize: "1rem" }}
            c="dark"
            lh={0}
          >
            <DescriptionText
              fw={600}
              style={{ display: "inline", fontStyle: "normal" }}
              c="dark"
              lh={0}
            >
              98%
            </DescriptionText>{" "}
            delivered on time
          </DescriptionText>
        </Stack>
      </Group>
    </Container>
  );
}

export default Metrics;
